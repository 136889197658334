.fashion-table {
  .ant-table {
    background-color: #1a1a1c;
  }
  .ant-table-thead > tr > th {
    background-color: #3a3a3c;
    border-color: #3a3a3c;
    color: #aeaeb2;
  }
  .ant-table-container {
    border-radius: 16px;
    overflow: hidden;
  }
  .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid #3a3a3c;
  }
  .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid #3a3a3c;
    border-left: 1px solid #3a3a3c;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: #8e8e93;
  }
  .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none;
  }
  .ant-table-fixed-header {
    .ant-table-body {
      overflow: auto !important;
    }
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 16px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 16px;
  }

  .ant-table-tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 16px;
  }

  .ant-table-tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 16px;
  }

  .ant-table-tbody > tr.ant-table-row > td,
  .ant-table-tbody > tr > td {
    background-color: #1a1a1c;
    color: #ffffff;
    border-color: #3a3a3c;
  }
  .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid #3a3a3c;
  }
  .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid #3a3a3c;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: #8e8e93;
  }

  // paggination
  .ant-table-pagination {
    .ant-pagination-item {
      background-color: #1a1a1c;
      border: none;
      a {
        color: #8e8e93;
      }
      &:hover {
        background-color: #ffffff;
        a {
          color: #1a1a1c;
        }
      }
    }

    .ant-pagination-item-active {
      background-color: #ffffff;
      border: none;
      a {
        color: #1a1a1c;
      }
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      border: none;
      color: #ffffff;
      background-color: transparent;
      .anticon {
        display: block;
        margin-top: -2px;
      }
    }
    &:hover {
      .ant-pagination-item-link {
        border: none;
        color: #1a1a1c;
        background-color: #ffffff;
      }
    }
  }

  .ant-pagination-disabled {
    .ant-pagination-item-link {
      color: #48484a;
      background-color: transparent;
    }
    &:hover {
      .ant-pagination-item-link {
        color: #48484a;
        background-color: transparent;
      }
    }
  }

  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    .ant-pagination-item-container {
      .ant-pagination-item-ellipsis {
        color: #8e8e93;
      }
      .ant-pagination-item-link-icon {
        color: #8e8e93;
      }
    }
  }
  .ant-pagination-jump-next-custom-icon,
  .ant-pagination-jump-prev-custom-icon {
    svg {
      margin-bottom: 10px;
    }
  }
  .ant-table-empty {
    .ant-table-tbody > tr.ant-table-placeholder:hover > td {
      background-color: transparent;
    }
  }
}
