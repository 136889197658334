/* windicss layer base */
*, ::before, ::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #EAECF0;
}
* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
}
:root {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
:-moz-ui-invalid {
  box-shadow: none;
}
::moz-focus-inner {
  border-style: none;
  padding: 0;
}
::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
a {
  color: inherit;
  text-decoration: inherit;
}
body {
  margin: 0;
  font-family: inherit;
  line-height: inherit;
}
button, input {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 0;
  line-height: inherit;
  color: inherit;
}
button {
  text-transform: none;
  background-color: transparent;
  background-image: none;
}
button, [type='button'], [type='reset'], [type='submit'] {
  -webkit-appearance: button;
}
button, [role="button"] {
  cursor: pointer;
}
html {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  line-height: 1.5;
}
h2, h1, h4, h3 {
  font-size: inherit;
  font-weight: inherit;
}
img {
  border-style: solid;
}
input::placeholder {
  opacity: 1;
  color: #98A2B3;
}
input::webkit-input-placeholder {
  opacity: 1;
  color: #98A2B3;
}
input::-moz-placeholder {
  opacity: 1;
  color: #98A2B3;
}
input:-ms-input-placeholder {
  opacity: 1;
  color: #98A2B3;
}
input::-ms-input-placeholder {
  opacity: 1;
  color: #98A2B3;
}
img, video {
  max-width: 100%;
  height: auto;
}
p, h2, h1, h4, h3 {
  margin: 0;
}
svg, img, video {
  display: block;
  vertical-align: middle;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
/* windicss layer components */
.container {
  width: 100%;
  margin: 0 auto;
}
.text-16px {
  font-size: 14px;
}
.text-xl {
  font-size: 17px;
}
.text-18px {
  font-size: 16px;
}
.text-24px {
  font-size: 16px;
}
.text-32px {
  font-size: 18px;
}
.text-40px {
  font-size: 20px;
}
.text-60px {
  font-size: 25px;
}
.text-64px {
  font-size: 36px;
}
@media (min-width: 576px) {
  .text-16px {
    font-size: 16px;
  }
  .text-xl {
    font-size: 17.5px;
  }
  .text-24px {
    font-size: 19px;
  }
  .text-32px {
    font-size: 24px;
  }
  .text-40px {
    font-size: 27px;
  }
  .text-18px {
    font-size: 16px;
  }
  .text-60px {
    font-size: 37px;
  }
  .text-64px {
    font-size: 39px;
  }
}
@media (min-width: 768px) {
  .text-18px {
    font-size: 18px;
  }
  .text-xl {
    font-size: 18px;
  }
  .text-24px {
    font-size: 20px;
  }
  .text-32px {
    font-size: 26px;
  }
  .text-40px {
    font-size: 30px;
  }
  .text-60px {
    font-size: 40px;
  }
  .text-64px {
    font-size: 42px;
  }
}
@media (min-width: 992px) {
  .text-18px {
    font-size: 18px;
  }
  .text-xl {
    font-size: 18.5px;
  }
  .text-24px {
    font-size: 22px;
  }
  .text-32px {
    font-size: 28px;
  }
  .text-40px {
    font-size: 33px;
  }
  .text-60px {
    font-size: 45px;
  }
  .text-64px {
    font-size: 47px;
  }
}
@media (min-width: 1200px) {
  .text-18px {
    font-size: 18px;
  }
  .text-xl {
    font-size: 19px;
  }
  .text-24px {
    font-size: 23px;
  }
  .text-32px {
    font-size: 30px;
  }
  .text-40px {
    font-size: 36px;
  }
  .text-60px {
    font-size: 50px;
  }
  .text-64px {
    font-size: 53px;
  }
}
@media (min-width: 1440px) {
  .container {
    max-width: 1686px;
  }
  .text-xl {
    font-size: 20px;
  }
  .text-18px {
    font-size: 18px;
  }
  .text-24px {
    font-size: 24px;
  }
  .text-32px {
    font-size: 32px;
  }
  .text-40px {
    font-size: 40px;
  }
  .text-60px {
    font-size: 60px;
  }
  .text-64px {
    font-size: 64px;
  }
}
@media (min-width: 576px) {
  .sm\:text-xl {
    font-size: 17px;
  }
  .sm\:text-18px {
    font-size: 16px;
  }
  @media (min-width: 576px) {
    .sm\:text-xl {
      font-size: 17.5px;
    }
    .sm\:text-18px {
      font-size: 16px;
    }
  }
  @media (min-width: 768px) {
    .sm\:text-18px {
      font-size: 18px;
    }
    .sm\:text-xl {
      font-size: 18px;
    }
  }
  @media (min-width: 992px) {
    .sm\:text-18px {
      font-size: 18px;
    }
    .sm\:text-xl {
      font-size: 18.5px;
    }
  }
  @media (min-width: 1200px) {
    .sm\:text-18px {
      font-size: 18px;
    }
    .sm\:text-xl {
      font-size: 19px;
    }
  }
  @media (min-width: 1440px) {
    .sm\:text-xl {
      font-size: 20px;
    }
    .sm\:text-18px {
      font-size: 18px;
    }
  }
}
@media (min-width: 992px) {
  .lg\:text-16px {
    font-size: 14px;
  }
  @media (min-width: 576px) {
    .lg\:text-16px {
      font-size: 16px;
    }
  }
}
@media (min-width: 1200px) {
  .xl\:text-xl {
    font-size: 17px;
  }
  @media (min-width: 576px) {
    .xl\:text-xl {
      font-size: 17.5px;
    }
  }
  @media (min-width: 768px) {
    .xl\:text-xl {
      font-size: 18px;
    }
  }
  @media (min-width: 992px) {
    .xl\:text-xl {
      font-size: 18.5px;
    }
  }
  @media (min-width: 1200px) {
    .xl\:text-xl {
      font-size: 19px;
    }
  }
  @media (min-width: 1440px) {
    .xl\:text-xl {
      font-size: 20px;
    }
  }
}
/* windicss layer utilities */
.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.bg-\[\#F5F9FF\] {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 249, 255, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-transparent {
  background-color: transparent;
}
.bg-\[\#8E8E93\] {
  --tw-bg-opacity: 1;
  background-color: rgba(142, 142, 147, var(--tw-bg-opacity));
}
.bg-\[\#3a3a3c\] {
  --tw-bg-opacity: 1;
  background-color: rgba(58, 58, 60, var(--tw-bg-opacity));
}
.bg-\[\#AEAEB2\] {
  --tw-bg-opacity: 1;
  background-color: rgba(174, 174, 178, var(--tw-bg-opacity));
}
.bg-\[\#3A3A3C\] {
  --tw-bg-opacity: 1;
  background-color: rgba(58, 58, 60, var(--tw-bg-opacity));
}
.bg-gray-9 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}
.bg-\[\#04051000\] {
  --tw-bg-opacity: 0;
  background-color: rgba(4, 5, 16, var(--tw-bg-opacity));
}
.bg-\[\#1A1A1C\] {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 26, 28, var(--tw-bg-opacity));
}
.focus\:bg-transparent:focus {
  background-color: transparent;
}
.hover\:bg-\[\#3A3A3C\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(58, 58, 60, var(--tw-bg-opacity));
}
.bg-\[\#01010C\] {
  --tw-bg-opacity: 1;
  background-color: rgba(1, 1, 12, var(--tw-bg-opacity));
}
.hover\:bg-\[\#37373b\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 55, 59, var(--tw-bg-opacity));
}
.bg-cultured {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 248, 249, var(--tw-bg-opacity));
}
.bg-\[\#FF8BB7\] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 139, 183, var(--tw-bg-opacity));
}
.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 41, 57, var(--tw-bg-opacity));
}
.bg-\[\#04040a\] {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 4, 10, var(--tw-bg-opacity));
}
.border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.border-\[\#636366\] {
  --tw-border-opacity: 1;
  border-color: rgba(99, 99, 102, var(--tw-border-opacity));
}
.border-\[\#B5B5B5\] {
  --tw-border-opacity: 1;
  border-color: rgba(181, 181, 181, var(--tw-border-opacity));
}
.border-\[\#fff\] {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.border-\[\#8E8E93\] {
  --tw-border-opacity: 1;
  border-color: rgba(142, 142, 147, var(--tw-border-opacity));
}
.hover\:border-\[\#AEAEB2\]:hover {
  --tw-border-opacity: 1;
  border-color: rgba(174, 174, 178, var(--tw-border-opacity));
}
.focus\:border-white:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.border-\[\#31323C\] {
  --tw-border-opacity: 1;
  border-color: rgba(49, 50, 60, var(--tw-border-opacity));
}
.hover\:border-\[\#31323C\]:hover {
  --tw-border-opacity: 1;
  border-color: rgba(49, 50, 60, var(--tw-border-opacity));
}
.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.hover\:border-red-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.border-\[\#3A3A3C\] {
  --tw-border-opacity: 1;
  border-color: rgba(58, 58, 60, var(--tw-border-opacity));
}
.hover\:border-white:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.border-\[\#E5E5E5\] {
  --tw-border-opacity: 1;
  border-color: rgba(229, 229, 229, var(--tw-border-opacity));
}
.border-brightGray {
  --tw-border-opacity: 1;
  border-color: rgba(228, 231, 237, var(--tw-border-opacity));
}
.border-\[\#e9e08e\] {
  --tw-border-opacity: 1;
  border-color: rgba(233, 224, 142, var(--tw-border-opacity));
}
.border-\[\#c5ac54\] {
  --tw-border-opacity: 1;
  border-color: rgba(197, 172, 84, var(--tw-border-opacity));
}
.border-\[\#af862c\] {
  --tw-border-opacity: 1;
  border-color: rgba(175, 134, 44, var(--tw-border-opacity));
}
.border-transparent {
  border-color: transparent;
}
.border-\[\#68A3F4\] {
  --tw-border-opacity: 1;
  border-color: rgba(104, 163, 244, var(--tw-border-opacity));
}
.border-\[\#AF862C\] {
  --tw-border-opacity: 1;
  border-color: rgba(175, 134, 44, var(--tw-border-opacity));
}
.border-\[\#98A2B3\] {
  --tw-border-opacity: 1;
  border-color: rgba(152, 162, 179, var(--tw-border-opacity));
}
.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(208, 213, 221, var(--tw-border-opacity));
}
.border-\[\#ff4d4f\] {
  --tw-border-opacity: 1;
  border-color: rgba(255, 77, 79, var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-4xl {
  border-radius: 2rem;
}
.rounded-\[50\%\] {
  border-radius: 50%;
}
.rounded-\[8px\] {
  border-radius: 8px;
}
.rounded-\[32px\] {
  border-radius: 32px;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-6xl {
  border-radius: 3rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-\[12px\] {
  border-radius: 12px;
}
.rounded-l-4xl {
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}
.border-none {
  border-style: none;
}
.border {
  border-width: 1px;
}
.border-2 {
  border-width: 2px;
}
.border-\[1\.5px\] {
  border-width: 1.5px;
}
.border-1 {
  border-width: 1px;
}
.border-\[2px\] {
  border-width: 2px;
}
.border-\[1px\] {
  border-width: 1px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-t-2 {
  border-top-width: 2px;
}
.border-b-\[2px\] {
  border-bottom-width: 2px;
}
.cursor-pointer {
  cursor: pointer;
}
.disabled\:cursor-default:disabled {
  cursor: default;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}
.table {
  display: table;
}
.grid {
  display: -ms-grid;
  display: grid;
}
.hidden {
  display: none;
}
.basis-0 {
  flex-basis: 0px;
}
.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}
.flex-grow {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.flex-shrink {
  -ms-flex-negative: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
}
.font-elMesseri {
  font-family: ElMessiri,sans-serif;
}
.font-medium {
  font-weight: 500;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.font-normal {
  font-weight: 400;
}
.font-light {
  font-weight: 300;
}
.h-10 {
  height: 2.5rem;
}
.h-12 {
  height: 3rem;
}
.h-full {
  height: 100%;
}
.h-11 {
  height: 2.75rem;
}
.h-auto {
  height: auto;
}
.h-8 {
  height: 2rem;
}
.h-0\.5 {
  height: 0.125rem;
}
.h-6 {
  height: 1.5rem;
}
.h-1\/5 {
  height: 20%;
}
.\!h-auto {
  height: auto !important;
}
.h-\[24px\] {
  height: 24px;
}
.h-\[100\%\] {
  height: 100%;
}
.h-\[42px\] {
  height: 42px;
}
.h-\[48px\] {
  height: 48px;
}
.h-\[350px\] {
  height: 350px;
}
.h-\[300px\] {
  height: 300px;
}
.h-\[100px\] {
  height: 100px;
}
.h-50 {
  height: 12.5rem;
}
.h-\[50px\] {
  height: 50px;
}
.h-\[850px\] {
  height: 850px;
}
.h-\[70\%\] {
  height: 70%;
}
.h-\[36px\] {
  height: 36px;
}
.h-\[220px\] {
  height: 220px;
}
.h-\[280px\] {
  height: 280px;
}
.h-\[600px\] {
  height: 600px;
}
.h-\[900px\] {
  height: 900px;
}
.h-\[400px\] {
  height: 400px;
}
.h-\[200px\] {
  height: 200px;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-\[12px\] {
  font-size: 12px;
  line-height: 1;
}
.text-\[14px\] {
  font-size: 14px;
  line-height: 1;
}
.text-\[24px\] {
  font-size: 24px;
  line-height: 1;
}
.text-\[16px\] {
  font-size: 16px;
  line-height: 1;
}
.text-\[18px\] {
  font-size: 18px;
  line-height: 1;
}
.text-14px {
  font-size: 14px;
  line-height: 1;
}
.text-12px {
  font-size: 12px;
  line-height: 1;
}
.text-36px {
  font-size: 36px;
  line-height: 1;
}
.text-20px {
  font-size: 20px;
  line-height: 1;
}
.text-\[40px\] {
  font-size: 40px;
  line-height: 1;
}
.leading-\[48px\] {
  line-height: 48px;
}
.leading-\[28px\] {
  line-height: 28px;
}
.leading-\[20px\] {
  line-height: 20px;
}
.leading-\[36px\] {
  line-height: 36px;
}
.leading-\[64px\] {
  line-height: 64px;
}
.m-6 {
  margin: 1.5rem;
}
.m-2 {
  margin: 0.5rem;
}
.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mr-0 {
  margin-right: 0px;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.mt-30 {
  margin-top: 7.5rem;
}
.mt-\[90px\] {
  margin-top: 90px;
}
.ml-3 {
  margin-left: 0.75rem;
}
.mt-\[1px\] {
  margin-top: 1px;
}
.ml-1 {
  margin-left: 0.25rem;
}
.mb-30 {
  margin-bottom: 7.5rem;
}
.mt-\[25\%\] {
  margin-top: 25%;
}
.mt-14 {
  margin-top: 3.5rem;
}
.mt-20 {
  margin-top: 5rem;
}
.mb-20 {
  margin-bottom: 5rem;
}
.mt-0 {
  margin-top: 0px;
}
.mb-50 {
  margin-bottom: 12.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-\[5\%\] {
  margin-bottom: 5%;
}
.ml-\[-65px\] {
  margin-left: -65px;
}
.mt-\[10\%\] {
  margin-top: 10%;
}
.max-h-\[200px\] {
  max-height: 200px;
}
.max-h-\[255px\] {
  max-height: 255px;
}
.max-h-\[140px\] {
  max-height: 140px;
}
.max-w-80 {
  max-width: 20rem;
}
.max-w-\[156px\] {
  max-width: 156px;
}
.max-w-100 {
  max-width: 25rem;
}
.max-w-\[1216px\] {
  max-width: 1216px;
}
.max-w-125 {
  max-width: 31.25rem;
}
.max-w-\[200px\] {
  max-width: 200px;
}
.max-w-\[1200px\] {
  max-width: 1200px;
}
.max-w-\[1184px\] {
  max-width: 1184px;
}
.max-w-\[900px\] {
  max-width: 900px;
}
.max-w-\[500px\] {
  max-width: 500px;
}
.max-w-\[976px\] {
  max-width: 976px;
}
.max-w-\[1120px\] {
  max-width: 1120px;
}
.max-w-\[340px\] {
  max-width: 340px;
}
.max-w-\[608px\] {
  max-width: 608px;
}
.max-w-\[800px\] {
  max-width: 800px;
}
.min-h-screen {
  min-height: 100vh;
}
.\!min-h-auto {
  min-height: auto !important;
}
.min-h-\[300px\] {
  min-height: 300px;
}
.min-h-\[1300px\] {
  min-height: 1300px;
}
.min-h-\[250px\] {
  min-height: 250px;
}
.min-h-300px {
  min-height: 300px;
}
.min-h-\[450px\] {
  min-height: 450px;
}
.min-h-600px {
  min-height: 600px;
}
.min-h-520px {
  min-height: 520px;
}
.\!min-w-auto {
  min-width: auto !important;
}
.min-w-\[1200px\] {
  min-width: 1200px;
}
.min-w-\[1000px\] {
  min-width: 1000px;
}
.min-w-\[300px\] {
  min-width: 300px;
}
.min-w-\[63px\] {
  min-width: 63px;
}
.object-contain {
  -o-object-fit: contain;
  object-fit: contain;
}
.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.p-4 {
  padding: 1rem;
}
.p-0 {
  padding: 0px;
}
.p-2\.5 {
  padding: 0.625rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-6 {
  padding: 1.5rem;
}
.p-5 {
  padding: 1.25rem;
}
.p-6\.5 {
  padding: 1.625rem;
}
.p-10 {
  padding: 2.5rem;
}
.p-2 {
  padding: 0.5rem;
}
.px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}
.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.py-15\.5 {
  padding-top: 3.875rem;
  padding-bottom: 3.875rem;
}
.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
.px-7\.5 {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}
.py-\[11px\] {
  padding-top: 11px;
  padding-bottom: 11px;
}
.px-\[12px\] {
  padding-left: 12px;
  padding-right: 12px;
}
.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}
.py-15 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}
.px-\[9px\] {
  padding-left: 9px;
  padding-right: 9px;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.py-\[3em\] {
  padding-top: 3em;
  padding-bottom: 3em;
}
.px-\[5px\] {
  padding-left: 5px;
  padding-right: 5px;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.px-15 {
  padding-left: 3.75rem;
  padding-right: 3.75rem;
}
.px-\[8px\] {
  padding-left: 8px;
  padding-right: 8px;
}
.pt-10 {
  padding-top: 2.5rem;
}
.pb-6 {
  padding-bottom: 1.5rem;
}
.pt-8 {
  padding-top: 2rem;
}
.pb-9 {
  padding-bottom: 2.25rem;
}
.pl-\[40px\] {
  padding-left: 40px;
}
.pl-1 {
  padding-left: 0.25rem;
}
.pr-10 {
  padding-right: 2.5rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pr-1 {
  padding-right: 0.25rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pt-12 {
  padding-top: 3rem;
}
.pb-30 {
  padding-bottom: 7.5rem;
}
.pl-\[16px\] {
  padding-left: 16px;
}
.pr-\[10px\] {
  padding-right: 10px;
}
.pt-5 {
  padding-top: 1.25rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pr-3 {
  padding-right: 0.75rem;
}
.pt-30 {
  padding-top: 7.5rem;
}
.pb-50 {
  padding-bottom: 12.5rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.pb-10 {
  padding-bottom: 2.5rem;
}
.\!pt-0 {
  padding-top: 0px !important;
}
.pt-7 {
  padding-top: 1.75rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pt-\[10px\] {
  padding-top: 10px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pt-20 {
  padding-top: 5rem;
}
.pt-\[5px\] {
  padding-top: 5px;
}
.pb-20 {
  padding-bottom: 5rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pt-0 {
  padding-top: 0px;
}
.pt-\[6\.5\%\] {
  padding-top: 6.5%;
}
.pb-\[7\%\] {
  padding-bottom: 7%;
}
.pb-3 {
  padding-bottom: 0.75rem;
}
.pb-8 {
  padding-bottom: 2rem;
}
.pb-\[100\%\] {
  padding-bottom: 100%;
}
.pt-\[8px\] {
  padding-top: 8px;
}
.placeholder-\[\#8E8E93\]::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(142, 142, 147, var(--tw-placeholder-opacity));
}
.placeholder-\[\#8E8E93\]::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(142, 142, 147, var(--tw-placeholder-opacity));
}
.placeholder-\[\#8E8E93\]:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(142, 142, 147, var(--tw-placeholder-opacity));
}
.placeholder-\[\#8E8E93\]::-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(142, 142, 147, var(--tw-placeholder-opacity));
}
.placeholder-\[\#8E8E93\]::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(142, 142, 147, var(--tw-placeholder-opacity));
}
.tab {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}
.pointer-events-none {
  pointer-events: none;
}
.static {
  position: static;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.inset-y-0 {
  top: 0px;
  bottom: 0px;
}
.left-0 {
  left: 0px;
}
.top-\[24px\] {
  top: 24px;
}
.left-\[11px\] {
  left: 11px;
}
.right-0 {
  right: 0px;
}
.top-0 {
  top: 0px;
}
.right-4 {
  right: 1rem;
}
.top-3 {
  top: 0.75rem;
}
.bottom-\[40px\] {
  bottom: 40px;
}
.bottom-\[-10px\] {
  bottom: -10px;
}
.bottom-\[-4px\] {
  bottom: -4px;
}
.bottom-\[-39px\] {
  bottom: -39px;
}
.bottom-0 {
  bottom: 0px;
}
.top-\[90px\] {
  top: 90px;
}
.top-\[48px\] {
  top: 48px;
}
.top-\[43\%\] {
  top: 43%;
}
.bottom-\[10px\] {
  bottom: 10px;
}
.right-\[15px\] {
  right: 15px;
}
.bottom-\[-3\%\] {
  bottom: -3%;
}
.top-\[10\%\] {
  top: 10%;
}
.right-\[-100\%\] {
  right: -100%;
}
.top-\[4px\] {
  top: 4px;
}
.left-\[100\%\] {
  left: 100%;
}
.left-\[-150px\] {
  left: -150px;
}
.top-\[33px\] {
  top: 33px;
}
.left-1\/2 {
  left: 50%;
}
.top-\[40\%\] {
  top: 40%;
}
.left-\[-20px\] {
  left: -20px;
}
.top-\[45\%\] {
  top: 45%;
}
.top-\[-3\%\] {
  top: -3%;
}
.left-\[2\%\] {
  left: 2%;
}
.right-\[10px\] {
  right: 10px;
}
.top-\[-8px\] {
  top: -8px;
}
.top-4 {
  top: 1rem;
}
.left-4 {
  left: 1rem;
}
.right-\[-4\%\] {
  right: -4%;
}
.top-\[1\.8\%\] {
  top: 1.8%;
}
.resize {
  resize: both;
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0/0.1),0 1px 2px -1px rgb(0 0 0/0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),0 1px 2px -1px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.text-darkCharcoal {
  --tw-text-opacity: 1;
  color: rgba(51, 51, 51, var(--tw-text-opacity));
}
.text-ruby {
  --tw-text-opacity: 1;
  color: rgba(255, 98, 165, var(--tw-text-opacity));
}
.text-\[\#FF8B00\] {
  --tw-text-opacity: 1;
  color: rgba(255, 139, 0, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-\[\#1A1A1C\] {
  --tw-text-opacity: 1;
  color: rgba(26, 26, 28, var(--tw-text-opacity));
}
.text-\[\#636366\] {
  --tw-text-opacity: 1;
  color: rgba(99, 99, 102, var(--tw-text-opacity));
}
.text-\[\#fff\] {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-\[\#E5E5EA\] {
  --tw-text-opacity: 1;
  color: rgba(229, 229, 234, var(--tw-text-opacity));
}
.text-\[\#AEAEB2\] {
  --tw-text-opacity: 1;
  color: rgba(174, 174, 178, var(--tw-text-opacity));
}
.text-\[\#8E8E93\] {
  --tw-text-opacity: 1;
  color: rgba(142, 142, 147, var(--tw-text-opacity));
}
.focus\:text-white:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.focus\:text-\[\#1A1A1C\]:focus {
  --tw-text-opacity: 1;
  color: rgba(26, 26, 28, var(--tw-text-opacity));
}
.text-disabled {
  --tw-text-opacity: 1;
  color: rgba(85, 80, 72, var(--tw-text-opacity));
}
.text-rhythm {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 142, var(--tw-text-opacity));
}
.text-\[\#C7C7CC\] {
  --tw-text-opacity: 1;
  color: rgba(199, 199, 204, var(--tw-text-opacity));
}
.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(208, 213, 221, var(--tw-text-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(152, 162, 179, var(--tw-text-opacity));
}
.text-error {
  --tw-text-opacity: 1;
  color: rgba(240, 68, 56, var(--tw-text-opacity));
}
.text-\[\#3A3A3C\] {
  --tw-text-opacity: 1;
  color: rgba(58, 58, 60, var(--tw-text-opacity));
}
.hover\:text-\[\#3A3A3C\]:hover {
  --tw-text-opacity: 1;
  color: rgba(58, 58, 60, var(--tw-text-opacity));
}
.text-\[\#64D2FF\] {
  --tw-text-opacity: 1;
  color: rgba(100, 210, 255, var(--tw-text-opacity));
}
.text-\[\#555048\] {
  --tw-text-opacity: 1;
  color: rgba(85, 80, 72, var(--tw-text-opacity));
}
.text-primary {
  --tw-text-opacity: 1;
  color: rgba(26, 26, 28, var(--tw-text-opacity));
}
.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(16, 24, 40, var(--tw-text-opacity));
}
.text-\[\#F4EE9D\] {
  --tw-text-opacity: 1;
  color: rgba(244, 238, 157, var(--tw-text-opacity));
}
.text-\[\#E7C448\] {
  --tw-text-opacity: 1;
  color: rgba(231, 196, 72, var(--tw-text-opacity));
}
.text-gray-25 {
  --tw-text-opacity: 1;
  color: rgba(252, 252, 253, var(--tw-text-opacity));
}
.truncate {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.uppercase {
  text-transform: uppercase;
}
.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.w-full {
  width: 100%;
}
.w-10 {
  width: 2.5rem;
}
.w-20 {
  width: 5rem;
}
.w-1\/2 {
  width: 50%;
}
.w-2\/3 {
  width: 66.666667%;
}
.w-8 {
  width: 2rem;
}
.w-1\/4 {
  width: 25%;
}
.w-2\/5 {
  width: 40%;
}
.w-3\/5 {
  width: 60%;
}
.\!w-full {
  width: 100% !important;
}
.w-\[24px\] {
  width: 24px;
}
.w-\[2px\] {
  width: 2px;
}
.w-\[48px\] {
  width: 48px;
}
.w-\[75\%\] {
  width: 75%;
}
.w-\[500px\] {
  width: 500px;
}
.w-\[260px\] {
  width: 260px;
}
.w-\[150px\] {
  width: 150px;
}
.w-\[45\%\] {
  width: 45%;
}
.w-\[48\%\] {
  width: 48%;
}
.w-\[25\%\] {
  width: 25%;
}
.w-\[60\%\] {
  width: 60%;
}
.w-\[70\%\] {
  width: 70%;
}
.w-\[300px\] {
  width: 300px;
}
.w-\[85\%\] {
  width: 85%;
}
.w-\[230px\] {
  width: 230px;
}
.z-10 {
  z-index: 10;
}
.z-99999 {
  z-index: 99999;
}
.z-0 {
  z-index: 0;
}
.z-20 {
  z-index: 20;
}
.z-1 {
  z-index: 1;
}
.z-9 {
  z-index: 9;
}
.z-12 {
  z-index: 12;
}
.z-5 {
  z-index: 5;
}
.z-11 {
  z-index: 11;
}
.z-9999 {
  z-index: 9999;
}
.z-14 {
  z-index: 14;
}
.gap-4 {
  grid-gap: 1rem;
  gap: 1rem;
}
.gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.gap-3 {
  grid-gap: 0.75rem;
  gap: 0.75rem;
}
.gap-7 {
  grid-gap: 1.75rem;
  gap: 1.75rem;
}
.gap-\[2em\] {
  grid-gap: 2em;
  gap: 2em;
}
.gap-\[16px\] {
  grid-gap: 16px;
  gap: 16px;
}
.gap-\[12px\] {
  grid-gap: 12px;
  gap: 12px;
}
.gap-\[8px\] {
  grid-gap: 8px;
  gap: 8px;
}
.gap-5 {
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.gap-10 {
  grid-gap: 2.5rem;
  gap: 2.5rem;
}
.gap-8 {
  grid-gap: 2rem;
  gap: 2rem;
}
.gap-20 {
  grid-gap: 5rem;
  gap: 5rem;
}
.gap-1 {
  grid-gap: 0.25rem;
  gap: 0.25rem;
}
.gap-6 {
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.gap-\[1em\] {
  grid-gap: 1em;
  gap: 1em;
}
.gap-15 {
  grid-gap: 3.75rem;
  gap: 3.75rem;
}
.gap-0 {
  grid-gap: 0px;
  gap: 0px;
}
.gap-y-2 {
  -webkit-row-gap: 0.5rem;
  -moz-row-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-translate-z: 0;
  --tw-rotate: 0;
  --tw-rotate-x: 0;
  --tw-rotate-y: 0;
  --tw-rotate-z: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scale-z: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  -ms-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
}
.translate-x-\[-50\%\] {
  --tw-translate-x: -50%;
}
.translate-y-1\/2 {
  --tw-translate-y: 50%;
}
.translate-x-1\/2 {
  --tw-translate-x: 50%;
}
.-translate-x-1\/2 {
  --tw-translate-x: -50%;
}
.transition {
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, filter, backdrop-filter;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform, filter, backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-in-out {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.animate-spin {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
.animated {
  -webkit-animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.animate {
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.\.\.\/animate {
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.animate\.transition {
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.blur {
  --tw-blur: blur(8px);
}
.scroll-smooth {
  scroll-behavior: smooth;
}
@media (min-width: 400px) {
  .xs\:flex-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .xs\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .xs\:absolute {
    position: absolute;
  }
}
@media (min-width: 576px) {
  .sm\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  .sm\:items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .sm\:h-\[500px\] {
    height: 500px;
  }
  .sm\:h-\[300px\] {
    height: 300px;
  }
  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .sm\:text-\[18px\] {
    font-size: 18px;
    line-height: 1;
  }
  .sm\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .sm\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .sm\:mt-10 {
    margin-top: 2.5rem;
  }
  .sm\:mb-20 {
    margin-bottom: 5rem;
  }
  .sm\:mt-16 {
    margin-top: 4rem;
  }
  .sm\:mt-20 {
    margin-top: 5rem;
  }
  .sm\:mt-0 {
    margin-top: 0px;
  }
  .sm\:max-w-\[600px\] {
    max-width: 600px;
  }
  .sm\:p-8 {
    padding: 2rem;
  }
  .sm\:px-18 {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .sm\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .sm\:pt-13 {
    padding-top: 3.25rem;
  }
  .sm\:pb-20 {
    padding-bottom: 5rem;
  }
  .sm\:relative {
    position: relative;
  }
  .sm\:left-0 {
    left: 0px;
  }
  .sm\:right-\[3\%\] {
    right: 3%;
  }
  .sm\:top-\[2\.6\%\] {
    top: 2.6%;
  }
  .sm\:w-auto {
    width: auto;
  }
  .sm\:w-10 {
    width: 2.5rem;
  }
  .sm\:w-full {
    width: 100%;
  }
  .sm\:w-2\/5 {
    width: 40%;
  }
  .sm\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }
  .sm\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem;
  }
  .sm\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 768px) {
  .md\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  .md\:h-10 {
    height: 2.5rem;
  }
  .md\:h-\[32px\] {
    height: 32px;
  }
  .md\:h-\[700px\] {
    height: 700px;
  }
  .md\:h-\[850px\] {
    height: 850px;
  }
  .md\:h-\[460px\] {
    height: 460px;
  }
  .md\:h-\[348px\] {
    height: 348px;
  }
  .md\:h-\[400px\] {
    height: 400px;
  }
  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .md\:mt-15 {
    margin-top: 3.75rem;
  }
  .md\:mt-20 {
    margin-top: 5rem;
  }
  .md\:mt-6 {
    margin-top: 1.5rem;
  }
  .md\:mb-3 {
    margin-bottom: 0.75rem;
  }
  .md\:mt-24 {
    margin-top: 6rem;
  }
  .md\:mt-40 {
    margin-top: 10rem;
  }
  .md\:mt-10 {
    margin-top: 2.5rem;
  }
  .md\:mt-4 {
    margin-top: 1rem;
  }
  .md\:p-3 {
    padding: 0.75rem;
  }
  .md\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .md\:pt-20 {
    padding-top: 5rem;
  }
  .md\:pb-50 {
    padding-bottom: 12.5rem;
  }
  .md\:pt-10 {
    padding-top: 2.5rem;
  }
  .md\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .md\:top-\[32px\] {
    top: 32px;
  }
  .md\:left-\[15px\] {
    left: 15px;
  }
  .md\:right-\[10\%\] {
    right: 10%;
  }
  .md\:left-\[-200px\] {
    left: -200px;
  }
  .md\:top-\[-33\%\] {
    top: -33%;
  }
  .md\:left-\[-3\%\] {
    left: -3%;
  }
  .md\:w-16 {
    width: 4rem;
  }
  .md\:w-\[32px\] {
    width: 32px;
  }
  .md\:w-\[90\%\] {
    width: 90%;
  }
  .md\:w-\[400px\] {
    width: 400px;
  }
  .md\:w-\[260px\] {
    width: 260px;
  }
  .md\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem;
  }
  .md\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 992px) {
  .lg\:block {
    display: block;
  }
  .lg\:flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  .lg\:hidden {
    display: none;
  }
  .lg\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  .lg\:justify-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .lg\:h-\[500px\] {
    height: 500px;
  }
  .lg\:h-\[300px\] {
    height: 300px;
  }
  .lg\:h-\[700px\] {
    height: 700px;
  }
  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .\!lg\:leading-8 {
    line-height: 2rem !important;
  }
  .lg\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .lg\:mt-24 {
    margin-top: 6rem;
  }
  .lg\:mr-4 {
    margin-right: 1rem;
  }
  .lg\:mt-0 {
    margin-top: 0px;
  }
  .lg\:mt-8 {
    margin-top: 2rem;
  }
  .lg\:mb-4 {
    margin-bottom: 1rem;
  }
  .lg\:mb-0 {
    margin-bottom: 0px;
  }
  .lg\:mr-6 {
    margin-right: 1.5rem;
  }
  .lg\:mt-60 {
    margin-top: 15rem;
  }
  .lg\:mt-10 {
    margin-top: 2.5rem;
  }
  .lg\:mt-6 {
    margin-top: 1.5rem;
  }
  .lg\:ml-\[-94px\] {
    margin-left: -94px;
  }
  .lg\:max-w-\[700px\] {
    max-width: 700px;
  }
  .lg\:p-4 {
    padding: 1rem;
  }
  .lg\:p-5 {
    padding: 1.25rem;
  }
  .lg\:px-18 {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .lg\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .lg\:pb-20 {
    padding-bottom: 5rem;
  }
  .lg\:pt-30 {
    padding-top: 7.5rem;
  }
  .lg\:pb-30 {
    padding-bottom: 7.5rem;
  }
  .lg\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .lg\:right-\[15\%\] {
    right: 15%;
  }
  .lg\:bottom-\[-10\%\] {
    bottom: -10%;
  }
  .lg\:top-\[-22\%\] {
    top: -22%;
  }
  .lg\:left-\[-2\%\] {
    left: -2%;
  }
  .lg\:text-left {
    text-align: left;
  }
  .lg\:w-3\/4 {
    width: 75%;
  }
  .lg\:w-40 {
    width: 10rem;
  }
  .lg\:w-2\/5 {
    width: 40%;
  }
  .lg\:w-1\/2 {
    width: 50%;
  }
  .lg\:w-25 {
    width: 6.25rem;
  }
  .lg\:w-\[38\%\] {
    width: 38%;
  }
  .lg\:w-\[80\%\] {
    width: 80%;
  }
  .lg\:w-\[50\%\] {
    width: 50%;
  }
  .lg\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  .lg\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }
  .lg\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1200px) {
  .xl\:block {
    display: block;
  }
  .xl\:flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  .xl\:hidden {
    display: none;
  }
  .xl\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  .xl\:items-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  .xl\:justify-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
  .xl\:h-8 {
    height: 2rem;
  }
  .xl\:h-\[800px\] {
    height: 800px;
  }
  .xl\:h-\[55\%\] {
    height: 55%;
  }
  .xl\:h-\[50px\] {
    height: 50px;
  }
  .xl\:h-\[550px\] {
    height: 550px;
  }
  .xl\:h-\[500px\] {
    height: 500px;
  }
  .xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .xl\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .xl\:mt-30 {
    margin-top: 7.5rem;
  }
  .xl\:mr-0 {
    margin-right: 0px;
  }
  .xl\:mt-10 {
    margin-top: 2.5rem;
  }
  .xl\:mt-8 {
    margin-top: 2rem;
  }
  .xl\:mt-20 {
    margin-top: 5rem;
  }
  .xl\:max-w-\[880px\] {
    max-width: 880px;
  }
  .xl\:min-h-\[350px\] {
    min-height: 350px;
  }
  .xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .xl\:py-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .xl\:pt-6 {
    padding-top: 1.5rem;
  }
  .xl\:pb-8 {
    padding-bottom: 2rem;
  }
  .xl\:pb-0 {
    padding-bottom: 0px;
  }
  .xl\:pt-\[6\%\] {
    padding-top: 6%;
  }
  .xl\:bottom-\[15\%\] {
    bottom: 15%;
  }
  .xl\:top-\[-16\%\] {
    top: -16%;
  }
  .xl\:text-left {
    text-align: left;
  }
  .xl\:w-1\/2 {
    width: 50%;
  }
  .xl\:w-full {
    width: 100%;
  }
  .xl\:w-2\/5 {
    width: 40%;
  }
  .xl\:w-\[60\%\] {
    width: 60%;
  }
  .xl\:w-\[55\%\] {
    width: 55%;
  }
  .xl\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }
  .xl\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }
  .xl\:gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1440px) {
  .\32 xl\:items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .\32 xl\:h-900px {
    height: 900px;
  }
  .\32 xl\:text-\[20px\] {
    font-size: 20px;
    line-height: 1;
  }
  .\32 xl\:text-\[60px\] {
    font-size: 60px;
    line-height: 1;
  }
  .\32 xl\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .\32 xl\:my-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .\32 xl\:mb-10 {
    margin-bottom: 2.5rem;
  }
  .\32 xl\:mt-0 {
    margin-top: 0px;
  }
  .\32 xl\:mt-10 {
    margin-top: 2.5rem;
  }
  .\32 xl\:mt-4 {
    margin-top: 1rem;
  }
  .\32 xl\:mt-20 {
    margin-top: 5rem;
  }
  .\32 xl\:mt-\[20\%\] {
    margin-top: 20%;
  }
  .\32 xl\:max-w-\[800px\] {
    max-width: 800px;
  }
  .\32 xl\:max-w-\[950px\] {
    max-width: 950px;
  }
  .\32 xl\:min-h-550px {
    min-height: 550px;
  }
  .\32 xl\:p-0 {
    padding: 0px;
  }
  .\32 xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .\32 xl\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .\32 xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .\32 xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .\32 xl\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .\32 xl\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .\32 xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .\32 xl\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .\32 xl\:right-\[20\%\] {
    right: 20%;
  }
  .\32 xl\:bottom-\[10\%\] {
    bottom: 10%;
  }
  .\32 xl\:left-\[-240px\] {
    left: -240px;
  }
  .\32 xl\:top-\[-22\%\] {
    top: -22%;
  }
  .\32 xl\:w-\[500px\] {
    width: 500px;
  }
  .\32 xl\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem;
  }
  .\32 xl\:gap-15 {
    grid-gap: 3.75rem;
    gap: 3.75rem;
  }
  .\32 xl\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }
}
@media (min-width: 1600px) {
  .desktop\:h-10 {
    height: 2.5rem;
  }
  .desktop\:h-\[72px\] {
    height: 72px;
  }
}
@media (min-width: 2000px) {
  .\33 xl\:h-\[70\%\] {
    height: 70%;
  }
  .\33 xl\:h-\[650px\] {
    height: 650px;
  }
  .\33 xl\:max-w-\[1180px\] {
    max-width: 1180px;
  }
  .\33 xl\:max-w-\[925px\] {
    max-width: 925px;
  }
  .\33 xl\:max-w-\[1300px\] {
    max-width: 1300px;
  }
  .\33 xl\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .\33 xl\:pb-15 {
    padding-bottom: 3.75rem;
  }
  .\33 xl\:pb-\[7\%\] {
    padding-bottom: 7%;
  }
  .\33 xl\:pt-8 {
    padding-top: 2rem;
  }
  .\33 xl\:bottom-\[3\%\] {
    bottom: 3%;
  }
  .\33 xl\:w-\[60\%\] {
    width: 60%;
  }
  .\33 xl\:gap-0 {
    grid-gap: 0px;
    gap: 0px;
  }
}
@media (max-width: 1439.9px) {
  .\<2xl\:w-140 {
    width: 35rem;
  }
}
@media (max-width: 1199.9px) {
  .\<xl\:w-130 {
    width: 32.5rem;
  }
}
@media (max-width: 991.9px) {
  .\<lg\:w-90 {
    width: 22.5rem;
  }
}
@media (max-width: 767.9px) {
  .\<md\:w-70 {
    width: 17.5rem;
  }
}
@media (max-width: 575.9px) {
  .\<sm\:max-h-\[200px\] {
    max-height: 200px;
  }
  .\<sm\:w-\[200px\] {
    width: 200px;
  }
  .\<sm\:w-50 {
    width: 12.5rem;
  }
}