.dropdown-setting {
  backdrop-filter: blur(12px);
  border-radius: 8px;
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: #ffffff;

    &:hover {
      color: #1A1A1C;
    }
  }

  .ant-dropdown-menu {
    background-color: #3a3a3c;
    border-radius: 8px;
  }

  .menu-item {
    background-color: #3a3a3c;
    border-radius: 8px;
    padding: 4px 0;
    display: flex;
    flex-direction: column;
    width: 120px;

    >* {
      padding: 5px 12px;
      color: white;
      display: flex;
      align-items: center;

      &:hover {
        background-color: white;
        color: #1A1A1C;
      }
    }
  }
  &.dropdown-nft {
    background-color: #3A3A3C;
  }
}