.ck-content {
  border: 1px solid #636366 !important;
  border-radius: 8px !important;
  height: 150px;
  &:hover,
  &:focus {
    border-color: #aeaeb2 !important;
  }
  * {
    color: #c7c7cc;
  }

  a {
    color: #64D2FF;
    &:hover {
      text-decoration: underline;
    }
  }
}

.error {
  .ck-content {
    border: 1px solid #ff453a !important;
  }
}

.privacy-content {
  font-size: 16px;
  line-height: 20px;
  color: #1a1a1c;

  &__heading {
    font-size: 60px;
    line-height: 56px;
    font-weight: 700;
    color: #1a1a1c;
    text-align: center;
    margin-bottom: 12px;
  }
  &__update-time {
    text-align: center;
    color: #636366;
    margin-bottom: 60px;
  }

  // style Typo
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    color: #1a1a1c;
    margin-bottom: 16px;
  }
  h1,
  h2,
  h3,
  h4 {
    margin-top: 32px;
  }
  h5,
  h6 {
    margin-top: 16px;
  }
  h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
  }
  h2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
  }
  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }
  h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
  h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

  p {
    font-size: 16px;
    color: #1a1a1c;
    line-height: 20px;
    margin-bottom: 16px;
  }

  a {
    text-decoration: underline;
  }

  ul {
    list-style: none;
    padding-left: 16px;
    margin-top: 12px;
    margin-bottom: 12px;
    li {
      font-size: 16px;
      line-height: 20px;
      color: #1a1a1c;
      margin-bottom: 10px;
    }
  }

  ol {
    list-style: none;
    padding-left: 16px;
    margin-top: 12px;
    margin-bottom: 12px;
    li {
      font-size: 16px;
      line-height: 20px;
      color: #1a1a1c;
      margin-bottom: 10px;
    }
  }
}

.contentPhysical,
.contentDigital,
.contentDesc,
.custom-editor {
  font-size: 16px;
  line-height: 20px;
  color: #1A1A1C;

  &__heading {
    font-size: 60px;
    line-height: 56px;
    font-weight: 700;
    color: #1A1A1C;
    text-align: center;
    margin-bottom: 12px;
  }
  &__update-time {
    text-align: center;
    color: #636366;
    margin-bottom: 60px;
  }

  // style Typo
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    color: #1A1A1C;
    margin-bottom: 16px;
  }
  h1,
  h2,
  h3,
  h4 {
    margin-top: 32px;
  }
  h5,
  h6 {
    margin-top: 16px;
  }
  h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
  }
  h2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
  }
  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }
  h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
  h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

  p {
    font-size: 16px;
    color: #1A1A1C;
    line-height: 20px;
    margin-bottom: 16px;
  }

  a {
    color: #64D2FF;
    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    list-style: disc;
    padding-left: 16px;
    margin-top: 12px;
    margin-bottom: 12px;
    li {
      font-size: 16px;
      line-height: 20px;
      color: #C7C7CC;
      margin-bottom: 10px;
    }
  }

  ol {
    list-style: decimal;
    padding-left: 16px;
    margin-top: 12px;
    margin-bottom: 12px;
    li {
      font-size: 16px;
      line-height: 20px;
      color: #C7C7CC;
      margin-bottom: 10px;
    }
  }
}

.custom-editor {
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
}
