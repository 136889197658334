.fashion-select {
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent !important;
    color: #333333;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    height: 40px;
    border: 1px solid #636366;
    align-items: center;
    border-radius: 8px;
  }
  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #aeaeb2;
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #aeaeb2;
    box-shadow: none;
  }
  .ant-select-arrow {
    color: #333333;
    transition: transform 0.3s;
  }
  &.ant-select-open .ant-select-arrow {
    transform: rotate(-180deg);
  }
}

.ant-select-dropdown {
  background-color: #fff;
  border-radius: 8px;
  color: #333333;
  padding: 10px 0;
  .ant-select-item {
    color: #333333;
    padding: 7px 12px;
    margin: 4px 0;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: black;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #ff62a5;
    color: white;
    font-weight: 400;
    padding: 7px 12px;
  }
}
