@font-face {
  font-family: "Futura";
  src: url("../../public/fonts/futura/SFUFuturaLight.TTF");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Futura";
  src: url("../../public/fonts/futura/SFUFuturaLightOblique.TTF");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Futura";
  src: url("../../public/fonts/futura/SFUFuturaBook.TTF");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Futura";
  src: url("../../public/fonts/futura/SFUFuturaBookOblique.TTF");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "ElMessiri";
  src: url("../../public/fonts/el-messiri/ElMessiri-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "ElMessiri";
  src: url("../../public/fonts/el-messiri/ElMessiri-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "ElMessiri";
  src: url("../../public/fonts/el-messiri/ElMessiri-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "ElMessiri";
  src: url("../../public/fonts/el-messiri/ElMessiri-Bold.ttf");
  font-weight: 200;
  font-style: normal;
}
@keyframes flip {
  0% {
    transform: rotateY(0deg) scale(0.4);
  }
  100% {
    transform: rotateY(360deg) scale(1);
  }
}

@keyframes right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.to-left {
  animation: left 1.75s ease-in-out;
}
.to-right {
  animation: right 0.75s ease-in-out;
}
.to-flip {
  animation: flip 0.75s ease-in-out;
}

@font-face {
  font-family: "Futura";
  src: url("../../public/fonts/futura/SFUFuturaRegular.TTF");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Futura";
  src: url("../../public/fonts/futura/SFUFuturaOblique.TTF");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Futura";
  src: url("../../public/fonts/futura/SFUFuturaHeavy.TTF");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Futura";
  src: url("../../public/fonts/futura/SFUFuturaHeavyOblique.TTF");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Futura";
  src: url("../../public/fonts/futura/SFUFuturaBold.TTF");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Futura";
  src: url("../../public/fonts/futura/SFUFuturaBoldOblique.TTF");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Futura";
  src: url("../../public/fonts/futura/SFUFuturaExtraBold.TTF");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Futura";
  src: url("../../public/fonts/futura/SFUFuturaExtraBoldOblique.TTF");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "InterTight";
  src: url("../../public/fonts/inter-tight/InterTight-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "InterTight";
  src: url("../../public/fonts/inter-tight/InterTight-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "InterTight";
  src: url("../../public/fonts/inter-tight/InterTight-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "InterTight";
  src: url("../../public/fonts/inter-tight/InterTight-SemiBoldItalic.ttf");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "InterTight";
  src: url("../../public/fonts/inter-tight/InterTight-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "InterTight";
  src: url("../../public/fonts/inter-tight/InterTight-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "InterTight";
  src: url("../../public/fonts/inter-tight/InterTight-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
}

html,
body {
  font-family: "ElMessiri", sans-serif !important;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1a1c;
}

html {
  height: auto;
}

body {
  overflow-x: hidden;
}

a {
  color: inherit;
}

* {
  box-sizing: border-box;
}

.w-fit {
  width: fit-content;
}

.h-fit {
  height: fit-content;
}

.slideItem {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.slideItemMobile {
  width: 100%;
  height: 100%;
  padding-bottom: 56.25%;
}

.backgroundOurService {
  background: linear-gradient(
    248.61deg,
    #606060 9.82%,
    #d0d0d0 29.91%,
    #929292 43.61%,
    #454545 71%,
    #afafaf 95.66%
  );
}

.alert-enter {
  width: 260px;
  -webkit-transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  transition: all 700ms ease;
}

.alert-enter-active {
  width: 55px;
}

.icon-audio {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-content: flex-end;
  transition: all 1s ease;
}

.icon-audio > span {
  width: 3px;
  height: 100%;
  border-radius: 1px;
  margin: 0 1.5px;
  transform-origin: bottom;
  transition: opacity 0.3s ease-out;
  content: "";
}

.pausedicon > span {
  transform: scaleY(0.05);
  margin-top: auto !important;
  transition: all 1s ease;
  background-color: white !important;
}

@-webkit-keyframes bouncePlayingLines {
  10% {
    transform: scaleY(0.25);
  }

  30% {
    transform: scaleY(0.83);
  }

  60% {
    transform: scaleY(0.42);
  }

  80% {
    transform: scaleY(0.625);
  }

  to {
    transform: scaleY(0.5);
  }
}

@keyframes bouncePlayingLines {
  10% {
    transform: scaleY(0.25);
  }

  30% {
    transform: scaleY(0.83);
  }

  60% {
    transform: scaleY(0.42);
  }

  80% {
    transform: scaleY(0.625);
  }

  to {
    transform: scaleY(0.5);
  }
}

.playingicon > span {
  -webkit-animation: bouncePlayingLines 2.2s ease infinite alternate;
  animation: bouncePlayingLines 2.2s ease infinite alternate;
  transform: scaleY(1);
  transition: all 1s ease;
  background-color: white !important;
}

.icon-audio > span:nth-of-type(2) {
  -webkit-animation-delay: -2.2s;
  animation-delay: -2.2s;
  transition: all 1s ease;
}

.icon-audio > span:nth-of-type(3) {
  -webkit-animation-delay: -3.7s;
  animation-delay: -3.7s;
  transition: all 1s ease;
}

.backgroundBtn {
  background: linear-gradient(
    89.83deg,
    #a9f3bf 0.12%,
    #9fb6f8 50.5%,
    #dfb1e2 99.84%
  );
}

.backgroundOpacity {
  background-color: rgba(26, 26, 28, 0.4) !important;
}

.backgroundOpacityHover {
  &:hover {
    background-color: rgba(26, 26, 28, 0.8) !important;
  }
}

.backdrop-filter {
  backdrop-filter: blur(10px);
}

.ant-form-item-explain-error {
  color: #ff453a;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  &::before {
    content: "";
    display: inline-block;
    background-image: url(/icons/error-field.svg);
    width: 11px;
    height: 11px;
    background-position: center;
    margin-right: 5px;
  }
}
.shadow-top-bottom {
  &:hover {
    box-shadow: 0 -10px 20px -5px rgba(115, 115, 115, 0.75),
      0 10px 20px -5px rgba(115, 115, 115, 0.75);
  }
}

.ant-select-dropdown {
  background-color: #3a3a3c;
  border-radius: 8px;
  color: white;
  padding: 10px 0;
  .ant-select-item {
    color: white;
    padding: 7px 12px;
    margin: 4px 0;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: black;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #f5f5f5;
    color: black;
    font-weight: 400;
    padding: 7px 12px;
  }
}

.ant-form-item .ck-editor__editable {
  color: #aeaeb2;
}

.loading-icon {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hover-scale {
  transition: all 0.3s ease !important;
  cursor: pointer;
}

.card {
  transition: all 0.4s ease;
  overflow: hidden;
  img {
    transition: all 0.4s ease;
  }
  &:hover {
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.12);
    transition: all 0.4s ease;
    img {
      transition: all 0.4s ease;
      transform: scale(1.1);
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ant-collapse-header,
.ant-collapse-content-box {
  padding: 0 !important;
}
.text-gradient {
  background: linear-gradient(
    180deg,
    #af862c 8.59%,
    #f8f29c 45.7%,
    #d9bd5c 66.66%,
    #ad842a 95.83%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.lick-active {
  background: #9fb6f8;
}
.slick-dots li {
  margin: 0px 9px;
}
.slick-dots li button:before {
  content: "";
  width: 10px;
  height: 10px;
  background: linear-gradient(
    184deg,
    #af862c -345.1%,
    #f8f29c 31.26%,
    #d9bd5c 243.82%,
    #ad842a 539.67%
  );
  display: block;
  transform: rotate(45deg);
  box-shadow: 0px 0px 10px 0px rgba(255, 122, 0, 0.5);
}
.slide-item {
  transition: filter 0.3s ease;
  opacity: 0.5;
}
.slick-slide.slick-center .slide-item,
.slick-slide.slick-active .slide-item {
  filter: none;
  transform: scale(1.1);
  opacity: 1;
}
.text-highlight {
  background: var(
    --Font-Primary,
    linear-gradient(
      180deg,
      #af862c 8.59%,
      #f8f29c 45.7%,
      #d9bd5c 66.66%,
      #ad842a 95.83%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
}
.highlight-bg {
  background: linear-gradient(
    180deg,
    rgba(192, 150, 69, 0) 28.57%,
    rgba(192, 150, 69, 0.09) 100%
  );
}
.custom-select.open {
  border: "none";
}

.custom-border {
  background: linear-gradient(
    180deg,
    hsla(36, 45%, 47%, 1) 0%,
    hsla(37, 42%, 22%, 1) 26%,
    hsla(37, 46%, 15%, 1) 51%,
    hsla(36, 46%, 29%, 1) 78%,
    hsla(36, 45%, 47%, 1) 100%
  );
  z-index: 1;
  border: 0;
  border-radius: inherit;
  position: relative;
}
.custom-border::after {
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1.5px;
  right: 1.5px;
  content: "";
  background: rgb(4, 4, 10);
  z-index: -1;
  border-radius: inherit;
}
.rounded-border {
  background: linear-gradient(
    180deg,
    hsla(36, 45%, 47%, 1) 0%,
    hsla(37, 42%, 22%, 1) 26%,
    hsla(37, 46%, 15%, 1) 51%,
    hsla(36, 46%, 29%, 1) 78%,
    hsla(36, 45%, 47%, 1) 100%
  );
  z-index: 1;
  border: 0;
  border-radius: 8px;
  position: relative;
}
.rounded-border::after {
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1.5px;
  right: 1.5px;
  content: "";
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.88) 0%,
      rgba(0, 0, 0, 0.88) 100%
    ),
    linear-gradient(180deg, #794c03 0%, #ffeac7 48.96%, #7e5d28 100%);
  z-index: -1;
  border-radius: 50%;
}
.ant-pagination-item {
  background: transparent !important;
  font-size: 18px;
  font-family: "El Messiri", sans-serif;
  font-weight: 500;
  font-style: normal;
  border: 0;
}
.ant-pagination-options {
  display: none;
}
.ant-drawer-content {
  background: url(/images/bg-mobile-menu.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.ant-drawer-header {
  border: none;
}
.scroll-container {
  overflow: auto;
}
.scroll-container::-webkit-scrollbar {
  width: 0.5em;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.scroll-container::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Show scrollbar on container hover */
.scroll-container:hover::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #1a1a1c;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .scroll-container:hover::-webkit-scrollbar {
    display: none;
  }
}
.scroll-container:hover::-webkit-scrollbar {
  width: 8px;
  border-radius: 8px;
  background-color: #636366;
  height: 6px;
}
.scroll-container:hover::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #636366;
}

.error-input {
  border-radius: 48px;
  border: 1.5px solid #31323c;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.94) 0%,
      rgba(0, 0, 0, 0.94) 100%
    ),
    linear-gradient(180deg, #927f61 0%, #ffd48e 48.96%, #927f61 100%);
  box-shadow: 0px 0px 24px 0px rgba(255, 0, 0, 0.4) inset;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 16px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-switch-checked {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.88) 0%,
      rgba(0, 0, 0, 0.88) 100%
    ),
    linear-gradient(180deg, #794c03 0%, #ffeac7 48.96%, #7e5d28 100%) !important;
  border: 1px solid #7e5d28;
  opacity: 1 !important;
}
.ant-switch-handle::before {
  background: linear-gradient(
    180deg,
    #af862c 8.59%,
    #f8f29c 45.7%,
    #d9bd5c 66.66%,
    #ad842a 95.83%
  );
}
.ant-switch-checked:focus {
  box-shadow: none;
}
.ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}
.ant-switch {
  border: 1px solid #31323c;
  opacity: 0.5;
}
.required-field::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 16px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
  -webkit-background-clip: text;
  -webkit-text-fill-color: red;
}
.ant-input-affix-wrapper > .ant-input {
  background: transparent !important;
  color: inherit;
}
.error-box-shadow {
  box-shadow: 0px 0px 80px 0px #ff000066 inset;
}
.dot:before {
  width: 10px;
  height: 10px;
  background: linear-gradient(
    184deg,
    #af862c -345.1%,
    #f8f29c 31.26%,
    #d9bd5c 243.82%,
    #ad842a 539.67%
  );
  display: block;
  transform: rotate(45deg);
  box-shadow: 0px 0px 10px 0px rgba(255, 122, 0, 0.5);
  content: "";
}
.control-dots {
  display: flex;
  justify-content: center;
  gap: 13px;
}

.carousel .control-dots .dot {
  width: 10px !important;
  height: 10px !important;
  background: linear-gradient(
    184deg,
    #af862c -345.1%,
    #f8f29c 31.26%,
    #d9bd5c 243.82%,
    #ad842a 539.67%
  ) !important;
}

.active-item {
  filter: none;
  transform: scale(1.1);
  opacity: 1;
}
.carousel-slider {
  height: 100%;
}

.text-gradient-new {
  background: linear-gradient(
    180deg,
    #e0ba68 8.59%,
    #cac362 43.93%,
    #d6c55e 91.67%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ant-input[disabled] {
  border-color: #31323C;
}