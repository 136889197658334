.ant-modal-content {
  border-radius: 12px;
}
.custom-modal {
  .ant-modal-content {
    background: #3a3a3c;
    box-shadow: 0px 4px 40px rgba(71, 66, 130, 0.37);
    border-radius: 12px;
    color: white;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close-x {
    color: white;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .custom-body {
    max-height: calc(100vh - 300px);
    overflow: auto;
  }

  .ant-modal-header {
    background: #3a3a3c;
    border-color: #636366;
    padding-top: 40px;
    padding-bottom: 16px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .ant-modal-title {
    text-align: center;
    color: white;
    font-size: 24px;
  }
}

.fashion-modal {
  .ant-modal-close {
    color: #555048;
  }
  .ant-modal-content {
    background: url(/images/bg-mobile-menu.png);
    background-repeat: no-repeat;
    background-size: cover;

    color: #ffffff;
    border-radius: 12px;
    padding-right: 20px;
    padding-left: 20px;
    position: relative;
  }
  .ant-modal-header {
    background: transparent;
    border: none;
    padding-left: 0;
    padding-right: 0;
  }
  .ant-modal-title {
    color: #ffffff;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
  }
  .ant-modal-body {
    padding-left: 0;
    padding-right: 0;
  }
  .ant-modal-footer {
    border-color: #48484a;
    display: flex;
    padding-left: 0;
    padding-right: 0;
  }

  &.title-center {
    .ant-modal-title {
      text-align: center;
    }
  }
  &.title-left {
    .ant-modal-title {
      text-align: left;
    }
  }
  &.title-right {
    .ant-modal-title {
      text-align: right;
    }
  }

  &.footer-center {
    .ant-modal-footer {
      justify-content: center;
    }
  }
  &.footer-left {
    .ant-modal-footer {
      justify-content: flex-start;
    }
  }
  &.footer-right {
    .ant-modal-footer {
      justify-content: flex-end;
    }
  }
  &.hidden-title {
    .ant-modal-body {
      padding-top: 55px;
    }
  }
}

.explore-nfts-modal {
  padding-bottom: 0;
  .ant-modal-close {
    color: #ffffff;
  }

  .ant-modal-content {
    background-color: #1a1a1c;
    color: #ffffff;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .ant-modal-body {
    flex-grow: 1;
  }
  .ant-modal-header {
    background-color: #1a1a1c;
    border-bottom: 1;
    border-color: #48484a;
  }
  .ant-modal-title {
    color: #ffffff;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

  .ant-modal-footer {
    border: none;
    display: flex;
  }

  .ant-modal-footer {
    justify-content: center;
  }
}

.explore-nfts-modal-search {
  // padding-bottom: 0;
  .ant-modal-close {
    color: #ffffff;
    top: 8px !important;
  }
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-header {
    background-color: #1a1a1c;
    border-bottom: 1;
    border-color: #48484a;
    padding-left: 0px;
  }
}

.ant-notification.ant-notification-topRight {
  margin-right: 0;
  top: auto !important;
  bottom: 24px !important;
  right: 40px !important;
  z-index: 2000;
  .ant-notification-notice-icon {
    margin-left: 0px;
  }
  .ant-notification-notice {
    border: 1px solid #636366;
    border-radius: 8px;
    color: white;
    padding: 12px 14px;
    display: flex;
    align-items: center;
    width: auto;
    margin-bottom: 8px;
    backdrop-filter: blur(12px);
  }

  .ant-notification-notice-close-x .anticon {
    color: white;
  }
}

.ant-notification-notice-error {
  background: rgba(26, 26, 28, 0.6);
}

.ant-notification-notice-success {
  background: rgba(26, 26, 28, 0.6);
}

.ant-notification-notice-warning {
  background: rgba(26, 26, 28, 0.6);
}

.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;

  .ant-notification-notice-message {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    margin-right: 35px;
    margin-left: 35px;
    color: white;
    border-right: 1px solid #8e8e93;
  }

  .ant-notification-notice-description {
    margin-left: 0;
  }
}

.ant-notification-notice-close {
  top: 50%;
  transform: translateY(-50%);
  .anticon {
    display: block;
  }
}

// .ant-scrolling-effect {
//   width: calc(100%)!important; //
// }

.ant-modal-wrap {
  overflow: hidden;
  z-index: 1050;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.modal-profile {
  margin-right: 12px;
  margin-left: auto;
  @media (max-width: 575px) {
    margin-left: 12px;
  }
  height: 100%;
  .ant-modal-content {
    background: url(/images/bg-mobile-menu.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.connect-wallet-modal {
  top: 240px;
  @media (max-width: 700px) {
    top: 150px;
  }
}

//exclusiveModal
.exclusiveModal {
  .ant-modal-content {
    border-radius: 16px;
  }
  .ant-modal-close {
    top: 24px;
    right: 24px;
  }
  .ant-modal-close-x {
    width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-body {
    padding: 60px 28px 24px 28px;
  }
}

.custom-wrapped-border,
.custom-wrapped-border:before {
  /* Your border styles */
  border-style: solid;
  border-color: #af862c; /* fallback color */
  border-image: linear-gradient(
    30deg,
    #af862c 0%,
    #f8f29c 42.54%,
    #d9bd5c 66.56%,
    #ad842a 100%
  );
  border-image-slice: 1;
}

.custom-wrapped-border {
  position: relative;
  width: 60%;
  height: 300px;
  border-width: 4px;
}

.custom-wrapped-border:before {
  content: " ";
  position: absolute;
  top: 3px;
  bottom: 3px;
  left: 3px;
  right: 3px;
  border-width: 2px;
}

.corner-element {
  position: absolute;
  width: 10px;
  height: 10px;
  background: linear-gradient(
    0deg,
    rgba(1, 1, 12, 0.94) 0%,
    rgba(1, 1, 12, 0.94) 100%
  );
}

.custom-wrapped-border-disabled,
.custom-wrapped-border-disabled:before {
  /* Your border styles */
  border-style: solid;
  border-color: #31323c; /* fallback color */
  border-image: linear-gradient(
    30deg,
    #31323c 0%,
    #31323c 42.54%,
    #31323c 66.56%,
    #31323c 100%
  );
  border-image-slice: 1;
}

.custom-wrapped-border-disabled {
  position: relative;
  width: 60%;
  height: 300px;
  border-width: 4px;
}

.custom-wrapped-border-disabled:before {
  content: " ";
  position: absolute;
  top: 3px;
  bottom: 3px;
  left: 3px;
  right: 3px;
  border-width: 2px;
}
